import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import ArticlePage from './pages/ArticlePage';
import NotFoundPage from './pages/NotFoundPage';
import Wordeely from './pages/Wordeely';
import WordeelyPrivacy from './pages/WordeelyPrivacy';
import BottomNavBar from './components/BottomNavBar';
import SubscribePage from './pages/SubscribePage';
import MiniSocialMediaAbout from './pages/MiniSocialMediaAbout';

function App() {
  return (
    <h6>Soon to return...</h6>
    // <BrowserRouter>
    //   <div className="App">
    //     <NavBar />
    //     <div id="page-body">
    //       <Routes>
    //         <Route path="/" element={<HomePage />} />
    //         <Route path="/:articleId" element={<ArticlePage />} />
    //         <Route path="/wordeely" element={<Wordeely />} />
    //         <Route path="/wordeely/privacypolicy" element={<WordeelyPrivacy />} />
    //         <Route path="/subscribe" element={<SubscribePage />} />
    //         <Route path="/MiniSocialMediaAbout" element={<MiniSocialMediaAbout />} />
    //         <Route path="*" element={<NotFoundPage />} />
    //       </Routes>
    //     </div>
    //     <br></br>
    //   </div>
    // </BrowserRouter>
  );
}

export default App;

